

export const usersService = {
    login,
    logout,
    getCurrentUser,
    updateUserInfo,
    getToken,
    updateToken,
};

const axios = require('axios').default;


function getToken() {
    const data = localStorage.getItem('token');
    if (data == null) {
        return null
    }
    const token = JSON.parse(data);
    if ((token.expires - (Date.now() / 1000)) < 86400) {
        updateToken(token.token)
    }
    return token.token;
}

function updateToken(token) {
    const config = {
        headers: {
            "Authorization": "Bearer " + token,
        }
    };
    axios.post(`/api/users/token`, {}, config).then(resp => {
        localStorage.setItem('token', JSON.stringify(resp.data));
    }).catch(err => {
        console.log("update token failed", err)
    });
}

function getCurrentUser() {
    const user = localStorage.getItem('user');
    return JSON.parse(user);
}

async function updateUserInfo() {
    const token = getToken()
    if (token == null) {
        throw Error("local token not found");
    }
    const config = {
        headers: {
            "Authorization": "Bearer " + token,
        }
    };
    const resp = await axios.get(`/api/users/me`, config);
    localStorage.setItem('user', JSON.stringify(resp.data.me));
}

async function login(username, password) {
    const res = await axios.post(`/api/auth/login`, {
        username: username,
        password: password
    });
    const data = res.data;
    localStorage.setItem('token', JSON.stringify(data));
    await updateUserInfo();
}

function logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
}
