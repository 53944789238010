import Vue from 'vue'
import Vuex from 'vuex'
import usersService from '@/services';

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        currentUser: null
    },
    mutations: {
        updateCurrentUser(state) {
            state.currentUser = usersService.getCurrentUser()
        }
    }
})

export default store;
