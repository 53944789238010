<template>
  <v-app>
    <v-app-bar app color="white" light dense>
      <a href="/">
        <v-img
          alt="logo"
          class="shrink mr-2"
          contain
          src="/images/doa_logo_full.svg"
          transition="scale-transition"
      /></a>

      <v-tabs center-active v-if="!$store.state.currentUser">
        <v-tab to="/signin">登录/Sign in</v-tab>
        <v-tab to="/signup">注册/Sign up</v-tab>
        <v-tab to="/password/recover">找回密码/Account Recovery</v-tab>
      </v-tabs>

      <v-tabs center-active show-arrows v-else-if="$store.state.currentUser.role == 'admin'">
        <v-tab to="/admin/users">管理用户/Users</v-tab>
        <v-tab to="/admin/camp">管理夏令营资料/Applications</v-tab>
        <v-tab to="/admin/group">管理分组/Groups</v-tab>
        <v-tab to="/admin/schedule">管理面谈时间/Schedules</v-tab>
      </v-tabs>

      <v-tabs center-active v-else-if="$store.state.currentUser.role == 'professor'">
        <v-tab to="/review/grade">夏令营资料评分/Grade</v-tab>
        <v-tab to="/review/schedule">夏令营面谈设置/Schedule</v-tab>
      </v-tabs>

      <v-tabs center-active v-else-if="$store.state.currentUser.role == 'student'">
        <v-tab to="/apply/detail">夏令营参加申请/Apply</v-tab>
        <v-tab to="/apply/schedule">夏令营面谈预约/Schedule</v-tab>
      </v-tabs>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <v-footer dark padless>
      <v-card flat tile class="flex white--text text-center">
        <v-list class="teal" v-if="$store.state.currentUser">
          <v-list-item>
            <v-list-item-avatar>
              <img :src="$store.state.currentUser.avatar" :alt="$store.state.currentUser.name" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="$store.state.currentUser.realname"></v-list-item-title>
              <v-list-item-subtitle v-text="$store.state.currentUser.role"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="handleLogout">
                    <v-icon>mdi-logout</v-icon>
                  </v-btn>
                </template>
                <span>退出登录/Logout</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} — <strong>DoA</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import usersService from "@/services";

export default {
  name: "App",
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    handleLogout() {
      usersService.logout();
      this.$store.commit("updateCurrentUser");
      this.$router.push("/");
    },
  },
  created() {
    this.$store.commit("updateCurrentUser");
  },
};
</script>
