import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueHtmlToPaper from 'vue-html-to-paper';
import router from './router'
import store from './store'

Vue.config.productionTip = false

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=no',
    'scrollbars=no'
  ],
  styles: [
  ]
}

Vue.use(VueHtmlToPaper, options);


new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
