import Vue from 'vue'
import VueRouter from 'vue-router'
import usersService from '../services'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: 'DoA - Home'
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/Signup.vue'),
    meta: {
      title: 'DoA - Signup'
    }
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('../views/Signin.vue'),
    meta: {
      title: 'DoA - Signin'
    }
  },
  {
    path: '/password/recover',
    name: 'passwordRecover',
    component: () => import('../views/Password/Recover.vue'),
    meta: {
      title: 'DoA - PasswordRecover'
    }
  },
  {
    path: '/password/reset',
    name: 'passwordReset',
    component: () => import('../views/Password/Reset.vue'),
    meta: {
      title: 'DoA - PasswordReset'
    }
  },
  {
    path: '/apply/edit',
    name: 'infoEdit',
    component: () => import('../views/Apply/Edit.vue'),
    meta: {
      title: 'DoA - InfoEdit'
    }
  },
  {
    path: '/apply/detail',
    name: 'infoDetail',
    component: () => import('../views/Apply/Detail.vue'),
    meta: {
      title: 'DoA - InfoDetail'
    }
  },
  {
    path: '/apply/schedule',
    name: 'schedule',
    component: () => import('../views/Apply/Schedule.vue'),
    meta: {
      title: 'DoA - Schedule'
    }
  },
  {
    path: '/review/grade',
    name: 'reviewGrade',
    component: () => import('../views/Review/Grade.vue'),
    meta: {
      title: 'DoA - ReviewGrade'
    }
  },
  {
    path: '/review/schedule',
    name: 'reviewSchedule',
    component: () => import('../views/Review/Schedule.vue'),
    meta: {
      title: 'DoA - ReviewSchedule'
    }
  },
  {
    path: '/review/detail/:id',
    name: 'reviewDetail',
    component: () => import('../views/Review/Detail.vue'),
    meta: {
      title: 'DoA - ReviewDetail'
    }
  },
  {
    path: '/admin/users',
    name: 'adminUsers',
    component: () => import('../views/Admin/Users.vue'),
    meta: {
      title: 'DoA - AdminUsers'
    }
  },
  {
    path: '/admin/camp',
    name: 'adminCamp',
    component: () => import('../views/Admin/Camp.vue'),
    meta: {
      title: 'DoA - AdminCamp'
    }
  },
  {
    path: '/admin/group',
    name: 'adminGroup',
    component: () => import('../views/Admin/Group.vue'),
    meta: {
      title: 'DoA - AdminGroup'
    }
  },
  {
    path: '/admin/schedule',
    name: 'adminSchedule',
    component: () => import('../views/Admin/Schedule.vue'),
    meta: {
      title: 'DoA - AdminSchedule'
    }
  },
  {
    path: '/upload',
    name: 'upload',
    component: () => import('../views/Upload.vue'),
    meta: {
      title: 'DoA - Upload'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // set page title every new route
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
  next();
})

router.beforeEach((to, from, next) => {
  // redirect to home page if not logged in and trying to access a restricted page
  const publicPages = ['/', '/signup', '/signin', '/upload', '/password/reset', '/password/recover',];
  const authRequired = !publicPages.includes(to.path);
  if (authRequired) {
    const user = usersService.getCurrentUser();
    if (!user) {
      return next('/');
    }
    // admin pages require role
    if (to.path.startsWith('/admin')) {
      if ((user.role != "admin") && (user.role != "owner")) {
        return next('/');
      }
    }
    // camp info pages require student
    if (to.path.startsWith('/review')) {
      if ((user.role != "professor") && (user.role != "admin") && (user.role != "owner")) {
        return next('/');
      }
    }
    // camp info pages require student
    if (to.path.startsWith('/apply')) {
      if (user.role != "student") {
        return next('/');
      }
    }
  }
  next();
})


export default router
